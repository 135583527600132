<template>
  <div class="relative flex-1">
    <div class="w-full bg-blue-400 text-white p-2 font-medium text-lg">
      Select Model
    </div>
    <div class="w-full text-gray-600 pl-4 pt-2 font-medium text-lg">
      Type
    </div>
    <div class="makes-container flex flex-wrap pb-2 pr-2 text-sm">
      <div class="w-1/4"><div v-on:click="selectPart('all')" :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 hover:bg-blue-100 hover:border-blue-600 cursor-pointer', part=='all' ? 'bg-blue-100 border-blue-500 hover:border-blue-500 text-blue-500' : 'border-gray-200 text-gray-500' ]">All</div></div>
      <div class="w-1/4" v-if="standard"><div v-on:click="selectPart('standard')" :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 hover:bg-blue-100 hover:border-blue-600 cursor-pointer', part=='standard' ? 'bg-blue-100 border-blue-500 hover:border-blue-500 text-blue-500' : 'border-gray-200 text-gray-500' ]">Passenger</div></div>
      <div class="w-1/4" v-if="suv"><div v-on:click="selectPart('suv')" :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 hover:bg-blue-100 hover:border-blue-600 cursor-pointer', part=='suv' ? 'bg-blue-100 border-blue-500 hover:border-blue-500 text-blue-500' : 'border-gray-200 text-gray-500'  ]">Suv</div></div>
      <div class="w-1/4" v-if="commercial"><div v-on:click="selectPart('commercial')" :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 hover:bg-blue-100 hover:border-blue-600 cursor-pointer', part=='commercial' ? 'bg-blue-100 border-blue-500 hover:border-blue-500 text-blue-500' : 'border-gray-200 text-gray-500' ]">All</div></div>
    </div>
    <div class="w-full text-gray-600 pl-4 pt-2 font-medium text-lg">
      Models
    </div>
    <div class="makes-container flex flex-wrap pb-2 pr-2 text-sm">
      <div v-for="model in getModels()" v-bind:key="model" class="w-1/4"><div v-on:click="select(model)" class="ml-2 mt-2 px-4 py-2 rounded-lg border-2 border-gray-200 text-gray-500 hover:bg-blue-100 hover:border-blue-600 hover:font-bold hover:text-blue-600 cursor-pointer">{{ model }}</div></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

export default {
  name: "model",
  data: function () {
    return {
      part: 'all',
      models: [],
      allModels: {},
      commercial: false,
      suv: false,
      standard: false,
      loading: true,
    }
  },
  methods: {
    selectPart: function (part) {
      this.part = part;
    },
    select: function (model) {
      this.$store.commit('tradein/setModels', this.allModels[model].ids);
      this.$store.commit('tradein/setCommercial', this.allModels[model].commercial);
      this.$store.commit('tradein/setPart', 'select-specs');
    },
    getModels: function() {
      return _.filter(_.keys(this.allModels), (modelKey) => {
        const model = this.allModels[modelKey];
        if(this.part=='standard') return !model.suv  && !model.commercial;
        if(this.part=='suv') return model.suv;
        if(this.part=='commercial') return model.commercial;
        return true;
      });
    }
  },
  created: function() {
    this.loading = true;
    this.models = [];
    this.part = 'all';
    axios.get("/manager/fleet/eurotax/models/", { params: {makeIds: this.$store.getters['tradein/makes'].join(','), registration : this.$store.getters['tradein/registration'] }}).then(response => {
      let newModels = {};
      this.models = [];
      if(response.status===200) this.models = response.data;
      _.forEach(this.models, (item) => {
        item.name = item.name.replace(" Dsl", "").trim();
        if(!_.has(newModels, item.name)) newModels[item.name] = { suv: item.suv, commercial: item.commercial, ids: []};
        newModels[item.name].ids.push(item.id);
      });
      this.allModels = newModels;
      this.commercial = _.filter(this.models, (model) => { return model.commercial }).length > 0
      this.suv = _.filter(this.models, (model) => { return model.suv }).length > 0
      this.standard = _.filter(this.models, (model) => { return !model.suv && !model.commercial }).length > 0
      this.part = 'all';
      this.loading = false;
    });
  },

}
</script>

<style scoped>

</style>